<template>
  <ReportPage>
    <section class="has-background-info has-text-light" style="padding:20px;">
      <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Customers</p>
          <p class="title has-text-light">
            {{currentBusiness.stats.customer_total}}
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Transactions</p>
          <p class="title has-text-light">
            {{currentBusiness.stats.transactions_total}}
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Min Spend</p>
          <p class="title has-text-light">
            {{ USD(currentBusiness.stats.spend_total).format() }}
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Estimated ROI</p>
          <p class="title has-text-light">
             {{currentBusiness.stats.estimated_roi}}
          </p>
        </div>
      </div>
    </nav>
    </section>

    <section class="section content">
      <p class="is-size-4">Customers spent over {{ USD(currentBusiness.stats.spend_total).format() }} to earn {{ USD(currentBusiness.stats.award_total).format() }}</p>
      <p class="is-size-4">Current balance: {{ USD(currentBusiness.balance).format() }}</p>
    </section>    
  </ReportPage>
</template>

<script>
import ReportPage from "@/components/ReportPage.vue";

export default {
  components: {
    ReportPage
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  },
  methods: {
    async loadData(status) {
      console.log(status)
      return []
    }
  }
}
</script>
